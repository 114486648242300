﻿.modal-footer .checkbox {
    margin-top: -5px;
    margin-bottom: 0px;
}


.modal-header {
    display: block;
    h3 {
        font-size: 12px;
        margin: 0;
    }

    h4 {
        font-size: 18px;
    }
}

.table-modal {
    .modal-dialog {
        width: 1272px;
        max-width: none;
        height: 600px;

        .small {
            width: 500px;
        }
    }
}


/*.modal-body.loading {
    height: 600px;
    overflow: hidden;

    &::before {
        content: " ";
        position: absolute;
        z-index: 11;
        top: 40%;
        left: 0;
        right: 0;
        width: 100px;
        height: 100px;
        margin: 0 auto;
        animation: sk-scaleout 1.0s infinite ease-in-out;
    }

    &::after {
        content: " ";
        display: block;
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        border-radius: 4px;
    }
}*/
