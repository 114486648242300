@import "vendors/custom-functions";
@import "vendors/custom-bootstrap-variables";
@import "vendors/custom-bootstrap";
@import "vendors/custom-dataTables.bootstrap4.scss";
/*@import "vendors/custom-dataTables-rowRecorder";*/
@import "vendors/custom-bootbox";
@import "vendors/animate";

@import "base/global";
@import "base/typography";

@import "components/loader";
@import "components/tables";
@import "components/iframe";
@import "components/radiobuttons";

@import url(https://use.fontawesome.com/releases/v5.2.0/css/all.css);