﻿.table.table tr {
    &:hover th,
    &:hover td {
        font-weight: bold;
    }

    /*th:nth-child(1) {
        width: 150px !important;
    }

    th:nth-child(3), th:nth-child(4) {
        width: 50px !important;
    }*/

    th, td {
        padding: .25rem .5rem;
        vertical-align: middle;
    }

    .btn {
        width: 100%;
        padding: .25rem .5rem;
    }
}
