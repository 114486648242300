.loading
{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
        content: '';
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0,0,0, 0.5);
        z-index: 9998;
    }
}

.loader
{
    width: 250px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 9999;
}

.dot
{
    width: 32px;
    height: 32px;
    position: relative;
    display: block;
    transform-origin: -50% center;
    border-radius: 15%;

    &:after
    {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background-color: lightblue;
        border-radius: 15%;
        box-shadow: 0px 0px 4px 0px rgba(#000000, 0.25);
    }

    &:nth-child(1)
    {
        animation: slide 1.5s ease-in-out infinite alternate;
        &:after{ animation: color-change 1.5s ease-in-out infinite alternate; }
    }

    @for $i from 1 to 5
    {
        &:nth-child(#{$i + 1})
        {
            animation: flip-#{$i} 1.5s ease-in-out infinite alternate;
            &:after{ animation: squidge-#{$i} 1.5s ease-in-out infinite alternate; }
        }
    }

    &:nth-child(2):after{ background-color: $gray-200; }
    &:nth-child(3):after{ background-color: $gray-400; }
    &:nth-child(4):after{ background-color: $gray-600; }
    &:nth-child(5):after{ background-color: $gray-800; }
}

@keyframes slide
{
    0% { background-color: #1795FF; transform: translatex(0px); }
    100% { background-color: #23D3FB; transform: translatex(210px); }
}

@keyframes color-change
{
    0% { background-color: white; }
    100% { background-color: black; }

}

@for $i from 1 to 5
{
    @keyframes flip-#{$i} {
        0%, #{$i * 15}% { transform: rotate(0); }
        #{$i * 15 + 20}%, 100% { transform: rotate(-180deg); }
    }

    @keyframes squidge-#{$i}
    {
        #{$i * 15 - 10}% { transform-origin: center bottom; transform: scalex(1) scaley(1);}
        #{$i * 15}% { transform-origin: center bottom; transform: scalex(1.3) scaley(0.7);}
        #{$i * 15 + 10}%, #{$i * 15 + 5}% { transform-origin: center bottom; transform: scalex(0.8) scaley(1.4);}
        #{$i * 15 + 40}%, 100% { transform-origin: center top; transform: scalex(1) scaley(1);}
        #{$i * 15 + 25}% { transform-origin: center top; transform: scalex(1.3) scaley(0.7);}
    }
}


.close-load {
    position: fixed;
    top: 12px;
    left: 12px;
    width: 36px;
    height: 36px;
    background: #ffffff;
    z-index: 9999;
    border-radius: 4px;

    cursor: pointer;

    &::before {
        content: '';
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        width: 4px;
        height: 24px;
        background: #000000;
    }

    &::after {
        content: '';
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        width: 4px;
        height: 24px;
        background: #000000;
    }
}
