$paper-margin: 10mm;
$paper-portrait-width: 210mm;
$paper-portrait-height: 297mm;
$paper-landscape-width: 297mm;
$paper-landscape-height: 210mm;

body {
    background: #e0e0e0;
}

.navbar.navbar-dark {
    background: #413f41;
    box-shadow: 0 0 10px #000000;

    .cta-buttons {
        button {
            overflow: hidden;
            width: auto;
            transition: width 0.75s ease-in-out;
        }

        span {
            margin-left: 5px;
        }
    }

    svg {
        width: 1rem;
        height: 1rem;
        display: inline-block;
        position: relative;
        top: 2px;
        margin-right: 1px;
        vertical-align: unset;
    }

    .vin-search {
        text-transform: uppercase;

        &::placeholder {
            text-transform: none;
        }
    }
}
