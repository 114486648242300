﻿$rb-width: 22px;
$rb-height: 22px;

.custom-radio {

    input {
        display: none;
    }

    input:checked + label::before {
        background: $white;
        border: 8px solid $primary;
    }

    label::before {
        content: '';
        width: $rb-width;
        height: $rb-height;
        display: inline-block;
        background: $white;
        border-radius: 100%;
        border: 2px solid $primary;
        transition: all .5s ease;
        margin-right: 5px;
    }

    label {
        position: relative;
        cursor: pointer;
    }
}
