﻿iframe.portrait {
    display: block;
    width: $paper-portrait-width + $paper-margin;
    height: $paper-portrait-height + $paper-margin;
    border: 0;
    margin: 100px auto 0;
}

iframe.landscape {
    display: block;
    width: $paper-landscape-width + $paper-margin;
    height: $paper-landscape-height + $paper-margin;
    border: 0;
    margin: 100px auto 0;
}
